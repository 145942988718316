import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4fc58c39 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _5b49d367 = () => interopDefault(import('../pages/account/absence.vue' /* webpackChunkName: "pages/account/absence" */))
const _64bbf1e2 = () => interopDefault(import('../pages/account/documents.vue' /* webpackChunkName: "pages/account/documents" */))
const _728a876a = () => interopDefault(import('../pages/account/journey.vue' /* webpackChunkName: "pages/account/journey" */))
const _476a566d = () => interopDefault(import('../pages/account/journey/index.vue' /* webpackChunkName: "pages/account/journey/index" */))
const _3c11bd94 = () => interopDefault(import('../pages/account/journey/exercises.vue' /* webpackChunkName: "pages/account/journey/exercises" */))
const _76f344d3 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _3456ce43 = () => interopDefault(import('../pages/account/project.vue' /* webpackChunkName: "pages/account/project" */))
const _40fc4269 = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _9687538c = () => interopDefault(import('../pages/activation.vue' /* webpackChunkName: "pages/activation" */))
const _2dc28e2e = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _e37cdf08 = () => interopDefault(import('../pages/chat.vue' /* webpackChunkName: "pages/chat" */))
const _4e26c9ff = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _2498674b = () => interopDefault(import('../pages/chat/archived-conversations/index.vue' /* webpackChunkName: "pages/chat/archived-conversations/index" */))
const _0b2b79ac = () => interopDefault(import('../pages/chat/bookmarks.vue' /* webpackChunkName: "pages/chat/bookmarks" */))
const _d9289380 = () => interopDefault(import('../pages/chat/conversations/index.vue' /* webpackChunkName: "pages/chat/conversations/index" */))
const _072020d6 = () => interopDefault(import('../pages/chat/drafts.vue' /* webpackChunkName: "pages/chat/drafts" */))
const _25d6b8d8 = () => interopDefault(import('../pages/chat/notifications/index.vue' /* webpackChunkName: "pages/chat/notifications/index" */))
const _2c66af2e = () => interopDefault(import('../pages/chat/templates/index.vue' /* webpackChunkName: "pages/chat/templates/index" */))
const _3a4fa74e = () => interopDefault(import('../pages/chat/peer-conversations/new.vue' /* webpackChunkName: "pages/chat/peer-conversations/new" */))
const _3806444b = () => interopDefault(import('../pages/chat/templates/new/_messageId.vue' /* webpackChunkName: "pages/chat/templates/new/_messageId" */))
const _56f248a8 = () => interopDefault(import('../pages/chat/conversations/_id.vue' /* webpackChunkName: "pages/chat/conversations/_id" */))
const _7ced1140 = () => interopDefault(import('../pages/chat/notifications/_id.vue' /* webpackChunkName: "pages/chat/notifications/_id" */))
const _61bb511a = () => interopDefault(import('../pages/chat/peer-conversations/_id.vue' /* webpackChunkName: "pages/chat/peer-conversations/_id" */))
const _0e0bb111 = () => interopDefault(import('../pages/chat/templates/_id.vue' /* webpackChunkName: "pages/chat/templates/_id" */))
const _81564980 = () => interopDefault(import('../pages/chat/unread-conversations/_id.vue' /* webpackChunkName: "pages/chat/unread-conversations/_id" */))
const _7eb901b8 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _6109438a = () => interopDefault(import('../pages/community/index/index.vue' /* webpackChunkName: "pages/community/index/index" */))
const _066973f6 = () => interopDefault(import('../pages/community/index/map.vue' /* webpackChunkName: "pages/community/index/map" */))
const _40f8a872 = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _84b697c0 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _70602e4d = () => interopDefault(import('../pages/edition/index.vue' /* webpackChunkName: "pages/edition/index" */))
const _7585e075 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _ef7192a4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _40f6bbf0 = () => interopDefault(import('../pages/recover.vue' /* webpackChunkName: "pages/recover" */))
const _22b98196 = () => interopDefault(import('../pages/resetPassword.vue' /* webpackChunkName: "pages/resetPassword" */))
const _78bc2caa = () => interopDefault(import('../pages/student-directory/index.vue' /* webpackChunkName: "pages/student-directory/index" */))
const _56f0c45d = () => interopDefault(import('../pages/token-expired.vue' /* webpackChunkName: "pages/token-expired" */))
const _64c6f6f9 = () => interopDefault(import('../pages/webinar/index.vue' /* webpackChunkName: "pages/webinar/index" */))
const _061ac288 = () => interopDefault(import('../pages/webinar/index/index.vue' /* webpackChunkName: "pages/webinar/index/index" */))
const _317b173c = () => interopDefault(import('../pages/webinar/index/bookmark.vue' /* webpackChunkName: "pages/webinar/index/bookmark" */))
const _4d20122e = () => interopDefault(import('../pages/webinar/index/search.vue' /* webpackChunkName: "pages/webinar/index/search" */))
const _3334875d = () => interopDefault(import('../pages/community/rules.vue' /* webpackChunkName: "pages/community/rules" */))
const _89fb5a7c = () => interopDefault(import('../pages/courses/empty.vue' /* webpackChunkName: "pages/courses/empty" */))
const _af9d3f8a = () => interopDefault(import('../pages/courses/free/index.ts' /* webpackChunkName: "pages/courses/free/index" */))
const _e82aa846 = () => interopDefault(import('../pages/edition/hot-seat/index.vue' /* webpackChunkName: "pages/edition/hot-seat/index" */))
const _549fedfd = () => interopDefault(import('../pages/onboarding/waiting.vue' /* webpackChunkName: "pages/onboarding/waiting" */))
const _2b9a9e3a = () => interopDefault(import('../pages/community/profile/edit.vue' /* webpackChunkName: "pages/community/profile/edit" */))
const _a9c51c8c = () => interopDefault(import('../pages/community/profile/_id.vue' /* webpackChunkName: "pages/community/profile/_id" */))
const _3de5d9cd = () => interopDefault(import('../pages/courses/free/_masterclassId/index.vue' /* webpackChunkName: "pages/courses/free/_masterclassId/index" */))
const _2afc0185 = () => interopDefault(import('../pages/edition/hot-seat/_id.vue' /* webpackChunkName: "pages/edition/hot-seat/_id" */))
const _61418de0 = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/index.ts' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/index" */))
const _04c6ed89 = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/_moduleId/index.ts' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/_moduleId/index" */))
const _0d074e46 = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/_moduleId/lessons/index.ts' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/_moduleId/lessons/index" */))
const _7943a630 = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/_moduleId/lessons/_lessonId.vue' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/_moduleId/lessons/_lessonId" */))
const _01c7f3ef = () => interopDefault(import('../pages/courses/_id.vue' /* webpackChunkName: "pages/courses/_id" */))
const _51838b32 = () => interopDefault(import('../pages/courses/_id/index.vue' /* webpackChunkName: "pages/courses/_id/index" */))
const _7fea1a98 = () => interopDefault(import('../pages/courses/_id/404.vue' /* webpackChunkName: "pages/courses/_id/404" */))
const _813789cc = () => interopDefault(import('../pages/courses/_id/certification.vue' /* webpackChunkName: "pages/courses/_id/certification" */))
const _19dc148b = () => interopDefault(import('../pages/courses/_id/lesson/index.vue' /* webpackChunkName: "pages/courses/_id/lesson/index" */))
const _c05ff95a = () => interopDefault(import('../pages/courses/_id/milestone/index.vue' /* webpackChunkName: "pages/courses/_id/milestone/index" */))
const _5bcf479a = () => interopDefault(import('../pages/courses/_id/lesson/_lesson_id.vue' /* webpackChunkName: "pages/courses/_id/lesson/_lesson_id" */))
const _c4e92bac = () => interopDefault(import('../pages/courses/_id/milestone/_milestone_id.vue' /* webpackChunkName: "pages/courses/_id/milestone/_milestone_id" */))
const _92614016 = () => interopDefault(import('../pages/edition/_id.vue' /* webpackChunkName: "pages/edition/_id" */))
const _43af5ab8 = () => interopDefault(import('../pages/edition/_id/index.vue' /* webpackChunkName: "pages/edition/_id/index" */))
const _0c8c6067 = () => interopDefault(import('../pages/edition/_id/planning.vue' /* webpackChunkName: "pages/edition/_id/planning" */))
const _44182ed2 = () => interopDefault(import('../pages/edition/_id/replays.vue' /* webpackChunkName: "pages/edition/_id/replays" */))
const _83767324 = () => interopDefault(import('../pages/follow-up/_id.vue' /* webpackChunkName: "pages/follow-up/_id" */))
const _4be7fec4 = () => interopDefault(import('../pages/onboarding/_page.vue' /* webpackChunkName: "pages/onboarding/_page" */))
const _20b8c002 = () => interopDefault(import('../pages/planning/_date.vue' /* webpackChunkName: "pages/planning/_date" */))
const _12323bef = () => interopDefault(import('../pages/signature/_id.vue' /* webpackChunkName: "pages/signature/_id" */))
const _252f38be = () => interopDefault(import('../pages/webinar/_id.vue' /* webpackChunkName: "pages/webinar/_id" */))
const _a8438544 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _4fc58c39,
    name: "account",
    children: [{
      path: "absence",
      component: _5b49d367,
      name: "account-absence"
    }, {
      path: "documents",
      component: _64bbf1e2,
      name: "account-documents"
    }, {
      path: "journey",
      component: _728a876a,
      children: [{
        path: "",
        component: _476a566d,
        name: "account-journey"
      }, {
        path: "exercises",
        component: _3c11bd94,
        name: "account-journey-exercises"
      }]
    }, {
      path: "profile",
      component: _76f344d3,
      name: "account-profile"
    }, {
      path: "project",
      component: _3456ce43,
      name: "account-project"
    }, {
      path: "settings",
      component: _40fc4269,
      name: "account-settings"
    }]
  }, {
    path: "/activation",
    component: _9687538c,
    name: "activation"
  }, {
    path: "/callback",
    component: _2dc28e2e,
    name: "callback"
  }, {
    path: "/chat",
    component: _e37cdf08,
    children: [{
      path: "",
      component: _4e26c9ff,
      name: "chat"
    }, {
      path: "archived-conversations",
      component: _2498674b,
      name: "chat-archived-conversations"
    }, {
      path: "bookmarks",
      component: _0b2b79ac,
      name: "chat-bookmarks"
    }, {
      path: "conversations",
      component: _d9289380,
      name: "chat-conversations"
    }, {
      path: "drafts",
      component: _072020d6,
      name: "chat-drafts"
    }, {
      path: "notifications",
      component: _25d6b8d8,
      name: "chat-notifications"
    }, {
      path: "templates",
      component: _2c66af2e,
      name: "chat-templates"
    }, {
      path: "peer-conversations/new",
      component: _3a4fa74e,
      name: "chat-peer-conversations-new"
    }, {
      path: "templates/new/:messageId?",
      component: _3806444b,
      name: "chat-templates-new-messageId"
    }, {
      path: "conversations/:id",
      component: _56f248a8,
      name: "chat-conversations-id"
    }, {
      path: "notifications/:id",
      component: _7ced1140,
      name: "chat-notifications-id"
    }, {
      path: "peer-conversations/:id?",
      component: _61bb511a,
      name: "chat-peer-conversations-id"
    }, {
      path: "templates/:id",
      component: _0e0bb111,
      name: "chat-templates-id"
    }, {
      path: "unread-conversations/:id?",
      component: _81564980,
      name: "chat-unread-conversations-id"
    }]
  }, {
    path: "/community",
    component: _7eb901b8,
    children: [{
      path: "",
      component: _6109438a,
      name: "community-index"
    }, {
      path: "map",
      component: _066973f6,
      name: "community-index-map"
    }]
  }, {
    path: "/courses",
    component: _40f8a872,
    name: "courses"
  }, {
    path: "/dashboard",
    component: _84b697c0,
    name: "dashboard"
  }, {
    path: "/edition",
    component: _70602e4d,
    name: "edition"
  }, {
    path: "/login",
    component: _7585e075,
    name: "login"
  }, {
    path: "/logout",
    component: _ef7192a4,
    name: "logout"
  }, {
    path: "/recover",
    component: _40f6bbf0,
    name: "recover"
  }, {
    path: "/resetPassword",
    component: _22b98196,
    name: "resetPassword"
  }, {
    path: "/student-directory",
    component: _78bc2caa,
    name: "student-directory"
  }, {
    path: "/token-expired",
    component: _56f0c45d,
    name: "token-expired"
  }, {
    path: "/webinar",
    component: _64c6f6f9,
    children: [{
      path: "",
      component: _061ac288,
      name: "webinar-index"
    }, {
      path: "bookmark",
      component: _317b173c,
      name: "webinar-index-bookmark"
    }, {
      path: "search",
      component: _4d20122e,
      name: "webinar-index-search"
    }]
  }, {
    path: "/community/rules",
    component: _3334875d,
    name: "community-rules"
  }, {
    path: "/courses/empty",
    component: _89fb5a7c,
    name: "courses-empty"
  }, {
    path: "/courses/free",
    component: _af9d3f8a,
    name: "courses-free"
  }, {
    path: "/edition/hot-seat",
    component: _e82aa846,
    name: "edition-hot-seat"
  }, {
    path: "/onboarding/waiting",
    component: _549fedfd,
    name: "onboarding-waiting"
  }, {
    path: "/community/profile/edit",
    component: _2b9a9e3a,
    name: "community-profile-edit"
  }, {
    path: "/community/profile/:id?",
    component: _a9c51c8c,
    name: "community-profile-id"
  }, {
    path: "/courses/free/:masterclassId",
    component: _3de5d9cd,
    name: "courses-free-masterclassId"
  }, {
    path: "/edition/hot-seat/:id?",
    component: _2afc0185,
    name: "edition-hot-seat-id"
  }, {
    path: "/courses/free/:masterclassId/modules",
    component: _61418de0,
    name: "courses-free-masterclassId-modules"
  }, {
    path: "/courses/free/:masterclassId/modules/:moduleId",
    component: _04c6ed89,
    name: "courses-free-masterclassId-modules-moduleId"
  }, {
    path: "/courses/free/:masterclassId/modules/:moduleId/lessons",
    component: _0d074e46,
    name: "courses-free-masterclassId-modules-moduleId-lessons"
  }, {
    path: "/courses/free/:masterclassId/modules/:moduleId/lessons/:lessonId",
    component: _7943a630,
    name: "courses-free-masterclassId-modules-moduleId-lessons-lessonId"
  }, {
    path: "/courses/:id",
    component: _01c7f3ef,
    children: [{
      path: "",
      component: _51838b32,
      name: "courses-id"
    }, {
      path: "404",
      component: _7fea1a98,
      name: "courses-id-404"
    }, {
      path: "certification",
      component: _813789cc,
      name: "courses-id-certification"
    }, {
      path: "lesson",
      component: _19dc148b,
      name: "courses-id-lesson"
    }, {
      path: "milestone",
      component: _c05ff95a,
      name: "courses-id-milestone"
    }, {
      path: "lesson/:lesson_id",
      component: _5bcf479a,
      name: "courses-id-lesson-lesson_id"
    }, {
      path: "milestone/:milestone_id",
      component: _c4e92bac,
      name: "courses-id-milestone-milestone_id"
    }]
  }, {
    path: "/edition/:id",
    component: _92614016,
    children: [{
      path: "",
      component: _43af5ab8,
      name: "edition-id"
    }, {
      path: "planning",
      component: _0c8c6067,
      name: "edition-id-planning"
    }, {
      path: "replays",
      component: _44182ed2,
      name: "edition-id-replays"
    }]
  }, {
    path: "/follow-up/:id?",
    component: _83767324,
    name: "follow-up-id"
  }, {
    path: "/onboarding/:page?",
    component: _4be7fec4,
    name: "onboarding-page"
  }, {
    path: "/planning/:date?",
    component: _20b8c002,
    name: "planning-date"
  }, {
    path: "/signature/:id?",
    component: _12323bef,
    name: "signature-id"
  }, {
    path: "/webinar/:id",
    component: _252f38be,
    name: "webinar-id"
  }, {
    path: "/",
    component: _a8438544,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
